import { NavLink } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-wrapper flex items-end justify-between text-sm w-full p-5 border-t border-[#ececec]">
        <div className="footer-wrapper-top flex flex-col items-start justify-center gap-4">
          <NavLink
            className="footer-logo max-w-[100px] md:max-w-[120px] block"
            to={"/"}
          >
            <img
              src="https://res.cloudinary.com/dipjef19s/image/upload/v1729065312/deskon_logo.png"
              alt="DeskOn Logo"
            />
          </NavLink>
          <div className="footer-text flex flex-col">
            <span>Your one-stop workspace inspiration hub!</span>
            <span>Shop the look. Build the dream.</span>
          </div>
          <div className="footer-social block mt-2">
            <a
              className="flex gap-2 items-center"
              href="https://www.instagram.com/deskon.hub/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram /> Follow us!
            </a>
          </div>
        </div>

        <div className="footer-wrapper-base">
          <p>
            &copy; {currentYear} <b>DeskOn</b>, All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
